import React from "react";
import LazyLoad from "react-lazyload";
import { graphql } from "gatsby";
import { withContext } from "~context";
import { BreadCrumbs } from "~components/assessment/bread-crumbs";
import { AssessmentVoiceList } from "~components/assessment/voice-list";
import { Assessment2PaneLayout } from "~components/templates/assessment-2pane-layout/page";
import "./index.scss";
import { PriceDocBanner } from "~components/assessment/price_doc_banner";
import { DocumentStyle } from "~components/templates/document-style";
import { Common2PaneSectionLayout } from "~components/templates/common-2pane-layout/section-layout";
import { Section, SubSection } from "~components/templates/section";
import { TableStyle } from "~components/templates/table-style";
import { AssessmentDocContact } from "~components/assessment/price_doc_contact";
import { Interview } from "~components/utils/interview";

type Props = {
  data: GatsbyBlockchainPageQuery;
};

const Component: React.FC<Props> = (props) => {
  const { data } = props;
  const CONTACT_FORM_ID =
    "31lfBkuItiC6zUlXxuv94ntAG0HPusBDbhRIM9Ck5f5X7VEJlguDsXpREs7pipVn5";

  return (
    <>
      <PriceDocBanner />
      <Assessment2PaneLayout
        formId={CONTACT_FORM_ID}
        meta={{
          title: "ブロックチェーン診断",
          description:
            "ブロックチェーン技術に関連するWebアプリケーション、もしくはブロックチェーンやスマートコントラクトを直接使用するWebアプリケーションに脆弱性がないか、経験豊富な診断員が検証します。",
        }}
      >
        <div className="Blockchain AssessmentCommonPage">
          <LazyLoad>
            <div className="AssessmentCommonTop BlockchainTop">
              <div className="AssessmentCommonTop_Wrapper">
                <Common2PaneSectionLayout>
                  <DocumentStyle width="no-limit">
                    <h1>ブロックチェーン診断</h1>
                    <p>
                      ブロックチェーン技術に関連するWebアプリケーション、もしくはブロックチェーンやスマートコントラクトを直接使用するWebアプリケーションに脆弱性がないか、経験豊富な診断員が検証します。Webアプリケーション側だけでなく、より踏み込んでSolidity等で実装されたスマートコントラクトやその他のブロックチェーン実装の脆弱性を調査することも可能です。
                    </p>
                  </DocumentStyle>
                </Common2PaneSectionLayout>
              </div>
            </div>
          </LazyLoad>

          <BreadCrumbs title="ブロックチェーン診断" />

          <Section background="subtle-gray">
            <SubSection>
              <Common2PaneSectionLayout>
                <DocumentStyle>
                  <h2>
                    <span>ブロックチェーン技術に</span>
                    <span>関連する</span>
                    <span>サービスの脅威</span>
                  </h2>
                  <p>
                    ブロックチェーン技術を活用したサービスの市場は急速な拡大を見せていますが、その開発に携わるエンジニアの全てがセキュリティに精通しているわけではなく、脆弱性が生まれてしまうのは珍しいことではありません。
                    <br />
                    <br />
                    しかも、その脆弱性はブロックチェーンやスマートコントラクト側だけでなく、ブロックチェーンを利用するためのWebサービス側にも発生し得ます。
                    <br />
                    <br />
                    どちら側に存在する脆弱性であれ、悪用されて生まれた不正なデータ・取引履歴は「高い改ざん耐性」というブロックチェーンの特徴により、しばしば後から修正できない被害へとつながります。データベースの修正が容易な通常のWebサービスとは比較にならない大きなリスクを孕むブロックチェーン関連サービスのセキュリティ対策は必須となっています。
                  </p>
                </DocumentStyle>
              </Common2PaneSectionLayout>
            </SubSection>
            <SubSection>
              <Common2PaneSectionLayout>
                <DocumentStyle>
                  <h3>インシデント事例</h3>
                </DocumentStyle>
                <div className="Blockchain_ParallelSectionWrapper">
                  <div className="Blockchain_ParallelSection">
                    <img
                      className="Blockchain_VulnerableIcon"
                      src={data?.vulnerable?.publicURL || ""}
                      alt=""
                    />
                    <DocumentStyle>
                      <h4>The DAO への攻撃</h4>
                      <p>
                        リエントラントを悪用した攻撃により、The
                        DAOと呼ばれるプロジェクトから約360万ETHが盗まれました。
                      </p>
                    </DocumentStyle>
                  </div>
                  <div className="Blockchain_ParallelSection">
                    <img
                      className="Blockchain_VulnerableIcon"
                      src={data?.vulnerable?.publicURL || ""}
                      alt=""
                    />
                    <DocumentStyle>
                      <h4>Beanstalk への攻撃</h4>
                      <p>
                        フラッシュローンを使用して投票権限を確保し、自身とウクライナの寄付アドレスに対する送金案を承認させ、Beanstalkと呼ばれるプロジェクトから7700万ドルほどが盗まれました。
                      </p>
                    </DocumentStyle>
                  </div>
                </div>
              </Common2PaneSectionLayout>
            </SubSection>
          </Section>

          <LazyLoad>
            <Section>
              <Common2PaneSectionLayout>
                <DocumentStyle>
                  <h2>
                    <span>Flatt Securityの</span>
                    <span>ブロックチェーン診断</span>
                  </h2>
                  <img src={data?.frontend?.publicURL || ""} alt="" />
                  <p>
                    ブロックチェーン技術を使用するWebサービスは、ブロックチェーンやスマートコントラクトがバックエンドとして、Webアプリケーションがユーザーとのインターフェイスであるフロントエンドとして機能します。
                    <br />
                    <br />
                    どちら側もブロックチェーン上に記録される修正不可能な被害を生む原因になり得るので、予算やサービスの仕様に応じてどちらを診断するかもしくは両方を診断するか選択する必要があります。
                    <br />
                    <br />
                    また、ブロックチェーンを直接使用はしないWebアプリケーションであっても、この分野に精通したセキュリティエンジニアがサービスの仕様を十分に理解してWebアプリケーション診断を行うことが可能です。
                    <br />
                    <br />
                    実施プランに関しては是非お気軽にFlatt
                    Securityにご相談ください。
                  </p>
                </DocumentStyle>
              </Common2PaneSectionLayout>
            </Section>
          </LazyLoad>

          <LazyLoad>
            <Section background="subtle-gray">
              <Common2PaneSectionLayout>
                <DocumentStyle>
                  <h2>診断プラン</h2>
                  <img src={data?.plan?.publicURL || ""} alt="" />
                </DocumentStyle>
              </Common2PaneSectionLayout>
              <Common2PaneSectionLayout>
                <div className="Blockchain_ParallelSectionWrapper">
                  <div className="Blockchain_ParallelSection">
                    <DocumentStyle>
                      <h3>Webアプリケーション診断プラン</h3>
                      <p>
                        Webアプリケーションを対象にして、XSSやSQLインジェクションなど典型的な脆弱性に加えて、認証・認可、決済等重要な機能のロジックに不備がないかセキュリティエンジニアが診断します。
                        <br />
                        <br />
                        診断観点は
                        <a href="/assessment/web_app">
                          「Webアプリケーション診断」ページ
                        </a>
                        をご覧ください。
                        <br />
                        <br />
                        こちらのプランではブロックチェーンを直接使用しないWebサービスであっても診断可能です。
                      </p>
                    </DocumentStyle>
                  </div>
                  <div className="Blockchain_ParallelSection">
                    <DocumentStyle>
                      <h3>ブロックチェーン診断プラン</h3>
                      <p>
                        サービスの仕様やユースケースと照らし合わせながら、Solidity等で実装されたスマートコントラクトやその他ブロックチェーンの実装が不適切でないかセキュリティエンジニアが診断します。
                        <br />
                        <br />
                        詳細な診断観点は次のセクションをご覧ください。
                        <br />
                        <br />
                        Webアプリケーション診断プランの観点も合わせて実施することも可能です。
                      </p>
                    </DocumentStyle>
                  </div>
                </div>
              </Common2PaneSectionLayout>
            </Section>
          </LazyLoad>

          <LazyLoad>
            <Section>
              <Common2PaneSectionLayout>
                <DocumentStyle>
                  <h2>診断観点</h2>
                  <p>
                    ブロックチェーン診断プランの観点です。Webアプリケーション診断プランの診断観点は
                    <a href="/assessment/web_app">
                      「Webアプリケーション診断」ページ
                    </a>
                    をご覧ください。
                  </p>
                  <TableStyle>
                    <table>
                      <tbody>
                        <tr>
                          <th>リエントラント</th>
                          <td>
                            攻撃対象のコントラクトが攻撃者によって制御可能なコントラクトを呼び出す際に、攻撃者のコントラクトから攻撃対象のコントラクトを再度呼び出します。すると、その時点で実行されている攻撃対象のコントラクト内の処理が完全に完了する前に同一コントラクト内の処理を実行し、意図しない挙動を引き起こすことが可能な場合があります。
                            <br />
                            <br />
                            このような脆弱性がないか実際にデプロイされた/される予定のコードを読み検証します。
                          </td>
                        </tr>
                        <tr>
                          <th>フロントランニング</th>
                          <td>
                            いくつかのブロックチェーンにおいて、実行前のトランザクションがmempoolと呼ばれる場所で公開されることを利用し、攻撃者にとって有利な取引を行うことが可能な場合があります。
                            <br />
                            <br />
                            このような脆弱性がないか実際にデプロイされた/される予定のコードを読み検証します。
                          </td>
                        </tr>
                        <tr>
                          <th>その他のロジックの不備</th>
                          <td>
                            上記以外に関してもリスクにつながるロジックの不備がないか、サービスの仕様やユースケースと照らし合わせながら実際にデプロイされた/される予定のコードを読み検証します。
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </TableStyle>
                </DocumentStyle>
              </Common2PaneSectionLayout>
            </Section>
          </LazyLoad>

          <LazyLoad>
            <Interview title="ブロックチェーン診断の事例インタビュー">
              <AssessmentVoiceList slugs={["anique", "doublejumptokyo"]} />
            </Interview>
          </LazyLoad>

          <LazyLoad>
            <Section background="brand">
              <Common2PaneSectionLayout>
                <DocumentStyle>
                  <h2>料金</h2>
                </DocumentStyle>
              </Common2PaneSectionLayout>
              <Common2PaneSectionLayout>
                <div className="Blockchain_ParallelSectionWrapper">
                  <div className="Blockchain_ParallelSection Blockchain_NoBackground">
                    <DocumentStyle>
                      <h3>Webアプリケーション診断プラン</h3>
                      <p>
                        <a href="/assessment/web_app">
                          「Webアプリケーション診断」
                        </a>
                        と同様に、リクエスト数に基づいてお見積りを行い料金を決定します。
                      </p>
                    </DocumentStyle>
                  </div>
                  <div className="Blockchain_ParallelSection  Blockchain_NoBackground">
                    <DocumentStyle>
                      <h3>ブロックチェーン診断プラン</h3>
                      <p>
                        ヒアリングとお見積もりを通して最終的な料金を決定させていただきます。
                      </p>
                    </DocumentStyle>
                  </div>
                </div>
              </Common2PaneSectionLayout>
            </Section>
          </LazyLoad>

          <AssessmentDocContact />
        </div>
      </Assessment2PaneLayout>
    </>
  );
};

export const query = graphql`
  query BlockchainPage {
    frontend: file(relativePath: { eq: "assessment/blockchain_frontend.png" }) {
      publicURL
    }
    plan: file(relativePath: { eq: "assessment/blockchain_plan.png" }) {
      publicURL
    }
    vulnerable: file(relativePath: { eq: "assessment/vulnerable.svg" }) {
      publicURL
    }
  }
`;

export default withContext(Component);
